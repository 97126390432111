body {

  background-image: url("bluebox-bg.png");
  background-color:#347AF0;
  font-family:'Titillium Web';
  
  
}
a {text-decoration: none;}

.App {
  text-align: center;
  
  background-color: black;
}
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');